<template>
  <div>
    <a-table
      :columns="tableInfo.columns"
      :data-source="tableInfo.data"
      :pagination="pagination"
      @change="pageChange"
    />
    <div class="close-but">
      <a-button @click="closePopup">关闭</a-button>
    </div>
  </div>
</template>
<script>
import { getUserIntegralInfoById } from "@/api/yearEndActivities";
import moment from "moment";
  export default {
    data() {
        return{
          //分页数据
          //分页数据
          pagination: {
            total: 0,
            showTotal: (total) => {
              return `共 ${total} 条`;
            },
          },
          tableInfo: {
            data: [],
            pageNo: 1,
            pageSize: 10,
            columns: [
              {
                title: '用户ID',
                dataIndex: 'userId',
                key: 'userId',
              },
              {
                title: '活动任务',
                dataIndex: 'type',
                key: 'type',
                customRender: (text, record) => {
                  if(!text){
                    return '-'
                  }
                  if(this.type === 1) {
                    if(text === 1) {
                      return '加入战队'
                    } else if(text === 2) {
                      return '分享'
                    }else if(text === 3) {
                      return '发帖'
                    }else if(text === 4) {
                      return '积分兑换'
                    }else if(text === 5) {
                      return '消费'
                    }else if(text === 6) {
                      return '楼宇点位'
                    }else if(text === 7) {
                      return '商户点亮'
                    } else if(text === 8) {
                      return '通关'
                    } else {
                      return '消费退款'
                    }
                  } else {
                    if(text === 1) {
                      return '领种子'
                    } else if(text === 2) {
                      return '分享'
                    }else if(text === 3) {
                      return '发帖'
                    }else if(text === 4) {
                      return '积分商城兑换'
                    }else if(text === 5) {
                      return '消费'
                    }else if(text === 9) {
                      return '消费退款'
                    }else if(text === 10) {
                      return '400填写'
                    } else if(text === 11) {
                      return '权益浏览'
                    } else if(text === 12) {
                      return '食住行'
                    }else if(text === 13) {
                      return '无忧创业'
                    }else if(text === 14) {
                      return '挖坑'
                    }else if(text === 15) {
                      return '写春联'
                    }else if(text === 16) {
                      return '通行'
                    } else if(text === 16) {
                      return '通行'
                    } else {
                      return '晚间消费退款'
                    }
                  }
                }
              },
              {
                title: '完成时间',
                dataIndex: 'createTime',
                key: 'createTime',
                customRender(text, record) {
                  return moment(text).format('YYYY-MM-DD HH:mm:ss')
                }
              },
              {
                title: '积分值',
                dataIndex: 'integralValue',
                key: 'integralValue',
              },
            ]
          }
        }
    },
    props: {
      // 当前要查看的用户id
      currentClickUserId: {
        type: String,
        required: true
      },
      type: {
        type: Number,
        required: true
      }
    },
    async created() {
      await this.getList();
    },
    methods: {
      /**
       * 转化text
       */
      async convertText(text) {

      },
      /**
       * 获取当前要查看的用户信息
       */
      async getList() {
        const params = this.transformParams()
        const res = await getUserIntegralInfoById(params);
        const { code, data: { items, total } } = res
        if(code === '200') {
          this.tableInfo.data = items
          this.pagination.total = total
        }
      },
      /**
       * 转化参数
       */
      transformParams() {
        return {
          pageNo: this.tableInfo.pageNo,
          pageSize: this.tableInfo.pageSize,
          userId: this.currentClickUserId
        }
      },
      /**
       * 关闭弹窗
       */
      closePopup() {
        this.$emit('close')
      },
      /**
       * 分页查询
       */
      pageChange(option) {
        this.tableInfo.pageNo = option.current;
        this.tableInfo.pageSize = option.pageSize;
        this.getList();
      }
    }

  }
</script>
<style scoped lang="scss">
.close-but {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 60px;
}
</style>